<template>
  <v-main>
    <v-progress-linear
      indeterminate
      color="blue-grey darken-1"
      v-if="loaded == false"
    ></v-progress-linear>
    <div class="A4 printonly" v-if="loaded == true">
      <section class="sheet printonly">
        <img src="/assets/images/logo.png" style="left:50%;margin-left: -63mm; top: 18mm;width: 126mm;height: 17mm;position: absolute;">
        <div
          :style="`height: 150mm;left:50%;top: 50mm;position: absolute;line-height: 0;width:` + plateSizeConfig.width +`;margin-left:` + plateSizeConfig.marginLeft">
          <PlateMap v-for="index in platesCount" :project="project" :colors="colors" :index="index-1"
                    :style="`width: ` + plateSizeConfig.cellSize+ `;height: ` + plateSizeConfig.cellSize + `;display: inline-block;margin: 1mm;`"
          />
        </div>
        <div style="bottom: 28mm;left: 28mm; right: 20mm;position: absolute;">
          <PlateLegend :project="project" :colors="colors" index="all" :drawColorNumbers="false"/>
        </div>
      </section>
      <section class="sheet printonly" v-for="index in platesCount">
        <img src="/assets/images/logo.png" style="left:50%;margin-left: -63mm; top: 18mm;width: 126mm;height: 17mm;position: absolute;">
<!--        <span style="position: absolute;top: 40mm;width: 100%;text-align: center;font-size: 8mm;color: #777776">{{ index }}/{{-->
<!--            platesCount-->
<!--          }}</span>-->
        <PlateMap :project="project" :colors="colors" :index="index-1"
                  :drawColorNumbers="false"
                  style="width: 168mm;height: 168mm;left:50%;margin-left: -84mm;top: 50mm;position: absolute;"/>
<!--        <div style="bottom: 28mm;left: 28mm; right: 20mm;position: absolute;">-->
<!--          <PlateLegend :project="project" :colors="colors" :index="index-1"/>-->
<!--        </div>-->
      </section>
      <section class="sheet printonly padding-15mm">
        <div style="text-align: center">
          Order Documentation <strong>{{ project.title }}</strong> version <strong>{{ project.version }}</strong>
        </div>
        <div style="text-align: right">
          <v-img class="no-aa"
                 :src="'/api/projects/preview/' + id + '?v=1'"
                 style="max-width: 20mm;max-height: 20mm;float: right;"
                 contain/>
          <div style="clear: both;"> {{ moment().format('DD.MM.YYYY') }}</div>
        </div>
        <div>
          <table>
            <tr>
              <th>Name</th>
              <th>Amount</th>
              <th>Collected</th>
            </tr>
            <tr v-for="item in printTableComplectation">
              <td>{{ item.title }}</td>
              <td style="text-align: right;">{{ item.amount }}</td>
              <td></td>
            </tr>
          </table>
        </div>
        <br/>
        <div>
          <table class="striped">
            <tr>
              <th style="width: 25%;">Color</th>
              <th style="width: 25%;">Weight</th>
              <th style="width: 25%;">Weighted</th>
              <th style="width: 25%;">Collected</th>
            </tr>
            <tr v-for="item in printTableColors">
              <td style="padding: 0;line-height: 0;">
                <svg width="7mm" height="7mm" viewBox="0 0 25 25" version="1.1"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     style="display: inline-block;margin: 0;padding: 0;">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Artboard" :fill="'#' + item.color.hex" v-html="item.color.svg">
                    </g>
                  </g>
                </svg>
              </td>
              <td style="text-align: right;">{{ item.weight }}</td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div>
        <br/>
        <div>
          <span>Assembled and checked according to the Order List<br/>
            _____________________________/_____________</span>
        </div>
      </section>
    </div>
  </v-main>
</template>


<style>

</style>


<script>
import '@/assets/styles/sm-print.css';
import {mapActions} from "vuex";

export default {
  name: "Print",
  data() {
    return {
      colorsMap: {},
      project: null,
      colors: null,
      withdrawInfo: null,
      loaded: false,
      id: 0,
      printMeta: null,
      printTableComplectation: null,
      printTableColors: null,
      plateSizeConfig: null,
    }
  },
  components: {
    PlateMap: () => import('@/components/projects/PlateMap.vue'),
    PlateLegend: () => import('@/components/projects/PlateLegend.vue'),
  },
  computed: {
    platesCount() {
      return this.project.meta.canvas_width * this.project.meta.canvas_height;
    },
    // plateSizeWidth() {
    //
    //   return '150mm';
    // },
    // plateSizeOnMap() {
    //   let sizeW = Math.ceil((150 - this.project.meta.canvas_width) / this.project.meta.canvas_width)
    //   let sizeH = Math.ceil((150 - this.project.meta.canvas_height) / this.project.meta.canvas_height)
    //   let size = Math.min(sizeW, sizeH)
    //   console.log('size', size)
    //   return size + 'mm';
    // }
    // ...mapGetters(['adminProjects', 'adminProjectsPages'])
  },
  methods: {
    ...mapActions('colors', ['loadColors']),
    calculateSize() {
      let overviewWidth = 160;
      if (this.project.meta.canvas_height > this.project.meta.canvas_width) {
        overviewWidth = 160 * (this.project.meta.canvas_width / this.project.meta.canvas_height)
      }

      let sizeW = Math.ceil((150 - this.project.meta.canvas_width) / this.project.meta.canvas_width)
      let sizeH = Math.ceil((150 - this.project.meta.canvas_height) / this.project.meta.canvas_height)
      let size = Math.min(sizeW, sizeH)

      this.plateSizeConfig = {
        width: overviewWidth + 'mm',
        cellSize: size + 'mm',
        marginLeft: -Math.ceil(overviewWidth / 2) + 'mm'
      }
      console.log(this.plateSizeConfig)
    },
    async getDataFromApi() {
      const promises = []
      promises.push(this.loadColors())
      promises.push(this.$api.projects.getProject({project_id: this.id}))
      promises.push(this.$api.projects.getProjectWithdrawInfo({project_id: this.id}))
      const [colorsResponse, projectResponse, withdrawInfoResponse] = await Promise.all(promises)
      this.colors = colorsResponse.colors
      this.project = projectResponse.project
      this.withdrawInfo = withdrawInfoResponse
      for (let item of this.colors) {
        this.colorsMap[item.id] = item;
      }
      this.printTableComplectation = this.withdrawInfo.table

      this.printTableColors = []
      for (let colorId in this.withdrawInfo.packages) {
        let colorPackage = this.withdrawInfo.packages[colorId]
        this.printTableColors.push({
          color: this.colorsMap[colorPackage.color_id],
          weight: colorPackage.m1
        })
      }

      this.calculateSize()
      this.loaded = true;
    },
  },
  async mounted() {
    this.id = this.$route.params.id
    await this.getDataFromApi()
  }

}
</script>

<style lang="scss" scoped>
* {
  font-size: 4mm;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table td, table th {
  border: 1px solid gray;
  padding: 0.5mm;
  margin: 0;
}

table.striped tr:nth-child(even) {
  background-color: #F6F6F6;
}
</style>
